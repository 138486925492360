import { createStore } from "vuex";

export default createStore({
  state: {
    pageLock: false
  },
  getters: {
    pageLock: state => state.pageLock
  },
  mutations: {
    changepageLockMutations(state,data){
			state.pageLock = data;
		},
  },
  actions: {},
  modules: {},
});
