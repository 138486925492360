import { createRouter, createWebHashHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";

const routes = [
  {
    path: "/about",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },
  {
    path: "/login",
    name: "Togin",
    meta: {
      title:'登录',  //header标题
      selfHeader: true
    },
    component: () => import("../views/Login.vue"),
  },
  // {
  //   path: "/",
  //   name: "HomeView",
  //   meta: {
  //     title:'首页'  //header标题
  //   },
  //   component: () => import("../views/task/TaskType.vue"),
  // },
  {
    path: "/",
    name: "TaskList",
    meta: {
      selfHeader: true,
      title:'任务列表'  //header标题
    },
    component: () => import("../views/task/TaskList.vue"),
  },{
    path: "/taskTree",
    name: "TaskTree",
    meta: {
      title:'任务列表',  //header标题
      rightContent: '订单详情'
    },
    component: () => import("../views/task/TaskTree.vue"),
  },{
    path: "/addTask",
    name: "AddTask",
    meta: {
      title:'添加任务'  //header标题
    },
    component: () => import("../views/task/AddTask.vue"),
  },{
    path: "/orderDetail",
    name: "OrderDetail",
    meta: {
      title:'订单详情'  //header标题
    },
    component: () => import("../views/task/OrderDetail.vue"),
  },{
    path: "/taskDetail",
    name: "TaskDetail",
    meta: {
      title:'任务详情',  //header标题
      rightContent: '提交'
    },
    component: () => import("../views/task/TaskDetail.vue"),
  },{
    path: "/delivery",
    name: "Delivery",
    meta: {
      backUrl: 'taskDetail',
      title:'添加交付物'  //header标题
    },
    component: () => import("../views/task/Delivery.vue"),
  }
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
