import axios from 'axios';//引用axios
import { showDialog } from 'vant'
import router from "@/router"
import store from "@/store"
axios.defaults.withCredentials = false;//表示跨域请求时是否需要使用凭证
// axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.baseURL = '';

//客户服务器-正式服务器
// let baseURL = document.location.protocol + "//" +document.domain;
// let baseURL = 'http://localhost:8080';
let baseURL = '';
//本地服务器



export default baseURL;

// 打包地址
let baselogin = "";

axios.interceptors.request.use((config) => {
    return config
}, (err) => {
    alert('请求超时');
    return Promise.resolve(err)
});

axios.interceptors.response.use((config) => {
    const url = config.request.responseURL.split('/')
    if (url[url.length-1] == 'login.jsp') {
        reLogin()
        return Promise.reject(config)
    } else {
        return config
    }
}, (err) => {
    alert('响应错误');
    return Promise.reject(err)
});

export function postRequest(url, params, header=null) {
    return axios({
        method: 'post',
        url: `${baseURL}${url}`,
        data: JSON.stringify(params),
        // transformRequest: [function (data) {  //transformRequest` 允许在向服务器发送前，修改请求数据，只能用在 'PUT', 'POST' 和 'PATCH' 这几个请求方法
        //     let returnData = '';
        //     for (let it in data) {
        //         returnData += encodeURIComponent(it) + '=' + encodeURIComponent(data[it]) + '&'  //对使用对应的编码替换所有非字母数字字符
        //     }
        //     return returnData
        // }],
        // `headers` 是即将被发送的自定义请求头
        headers: header ? header : {'Content-Type': 'application/json'} //请求头信息
    });
}

export function postRequestFile(url, params) {
    return axios({
        method: 'post',
        url: `${baseURL}${url}`,
        data: params,
        // `headers` 是即将被发送的自定义请求头
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded', //请求头信息
        }
    });
}

export function postRequestBlob(url,params){
    return axios({
        method: 'post',
        url: `${baseURL}${url}`,
        data: params,
        // `headers` 是即将被发送的自定义请求头
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8', //请求头信息
        },
        responseType: 'blob'
    });
}

export function postRequestest(url, params) {
    return axios({
        method: 'post',
        url: `${baseURL}${url}`,
        data: params,
    })
}


export function postRequests(url, params) {
    return axios({
        method: 'post',
        url: `${baselogin}${url}`,
        data: params
    })
}


export function putRequest(url, params) {
    return axios({
        method: 'put',
        url: `${baseURL}${url}`,
        data: params,
        transformRequest: [function (data) {
            let returnData = '';
            for (let it in data) {
                returnData += encodeURIComponent(it) + '=' + encodeURIComponent(data[it]) + '&'
            }
            return returnData
        }],
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    })
}


export function deleteRequest(url) {
    return axios({
        method: 'delete',
        url: `${baseURL}${url}`
    })
}

export function getRequest(url) {
    return axios({
        method: 'get',
        url: `${baseURL}${url}`,
        headers: {
            'Content-Type': 'text/html, text/plain',
        }
    })
}

export function getRequests(url) {
    return axios({
        method: 'get',
        url: `${baseURL}${url}`,
        responseType: 'arraybuffer',
    })
}

//判断当前手机环境
export function sUserAgent() {
    var is_wechat = null;
    var sUserAgent = navigator.userAgent.toLowerCase();
    var bIsIpad = sUserAgent.match(/ipad/i) == "ipad";
    var bIsIphoneOs = sUserAgent.match(/iphone os/i) == "iphone os";
    var bIsMidp = sUserAgent.match(/midp/i) == "midp";
    var bIsUc7 = sUserAgent.match(/rv:1.2.3.4/i) == "rv:1.2.3.4";
    var bIsUc = sUserAgent.match(/ucweb/i) == "ucweb";
    var bIsAndroid = sUserAgent.match(/android/i) == "android";
    var bIsCE = sUserAgent.match(/windows ce/i) == "windows ce";
    var bIsWM = sUserAgent.match(/windows mobile/i) == "windows mobile";
    if (
        bIsIpad ||
        bIsIphoneOs ||
        bIsMidp ||
        bIsUc7 ||
        bIsUc ||
        bIsAndroid ||
        bIsCE ||
        bIsWM
    ) {
        if (sUserAgent.match(/MicroMessenger/i) == "micromessenger") {
            //手机端在微信中打开
            return 'is_wechat'
        } else {
            //手机H5
            return 'is_h5'
        }
    } else {
        //PC
        return 'pc'
    }
}

export function wxLogin(appid, redirect = '') {
    //静默：snsapi_base，非静默：snsapi_userinfo
    var code_url =
      "https://open.weixin.qq.com/connect/oauth2/authorize?appid=" +
      appid +
      "&redirect_uri=" +
      encodeURIComponent(document.location.protocol + "//" + document.domain + (redirect ? `/#/${redirect}` : '')) +
      // encodeURIComponent(this.theDomian + "/#/login") +
      "&response_type=code&scope=snsapi_base&state=STATE#wechat_redirect";
    location.href = code_url;
  }
  export function getCode (authCode, callback = ()=>{}) {
    postRequests("/commonapp/common!initOpenid.action" + `?code=${authCode}`).then(res => {
      return new Promise((resolve,reject)=>{
        resolve(res.data.responseCode)
      })
    }).then(re => {
      if(re == 'success'){
        callback()
      }
    });
  }

  export function loginByOpenid(callback = ()=>{}) {
    store.commit('changepageLockMutations',true)
    postRequests("/commonapp/common!wechatLogin.action").then(res => {
      if(res.data.responseCode == 'success'){
        const url = sessionStorage.getItem('lastPage') || window.location.origin
        callback(res.data)
        window.location.href = url
      } else{
        showDialog({
          message: '登录失败，请重新登录。'
        })
      }
    }).finally(()=>{
        setTimeout(()=>{
            store.commit('changepageLockMutations',false)
        }, 1000)
    })
  }
//重新登录
export function reLogin() {
    // postRequests('commonapp/common!reLogin.action',{
    //     loginName: "LING",
    //     password: "123456",
    //     appAccessKey: localStorage.getItem('appAccessKey')
    // }).then(res => {
    //     if (res.data.responseCode != 'success') {
    //         showDialog({
    //             title: '登录',
    //             message: '登录超时，请重试登录',
    //         }).then(() => {
    //             router.push({
    //                 path: '/login'
    //             })
    //         })
    //     } else {
    //         location.reload()
    //     }
    // })
    showDialog({
        title: '登录',
        message: '登录超时，请重试登录',
    }).then(() => {
        store.commit('changepageLockMutations',true)
        router.push({
            path: '/login'
        })
    })
}
