<template>
    <div class="cd-header">
        <template v-if="$slots.back">
            <slot name="back"></slot>
        </template>
        <van-icon v-else name="arrow-left" style="margin:0 10px" @click="back" />
        <div class="header-title">{{ title }}</div>
        <div class="right-content" @click="rightClick">{{ rightContent }}</div>
    </div>
</template>

<script>
export default {
    name: 'CdHeader',
    props: {
        title: {
            type: String,
            default: ''
        },
        rightContent: {
            type: String,
            default: ''
        }
    },
    methods: {
        back () {
            this.$emit('back')
        },
        rightClick () {
            this.$emit('rightClick')
        }
    }
}
</script>

<style lang="less" scoped>
.cd-header{
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #0094ff;
    color: #fff;
    width: 100vw;
    height: 60px;
    line-height: 60px;
    .header-title{ 
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }
    .right-content{
        min-width: 36px;
        margin: 0 10px;
        font-size: 14px;
    }
}
</style>