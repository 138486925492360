<template>
    <div class="load-container">
        <van-popup v-model:show="load" class="loading-popup" :close-on-click-overlay="false" overlay-class="overlay">
            <div class="cd-loading-box">
                <van-loading size="40px" color="#fff" text-color="#fff" text-size="20px" vertical>{{text}}</van-loading>
            </div>
        </van-popup>
    </div>
</template>

<script>
export default {
    name: 'CdLoading',
    data() {
        return {
            load: false
        }
    },
    props:{
        text:{
            type:String,
            default:'加载中...'
        },
        modelValue: {
            type: String
        }
    },
    watch: {
        modelValue(val){
            this.load = val
        }
    }
}
</script>

<style scoped>
.load-container >>> .overlay{
    background-color: transparent;
    
}
.load-container >>> .loading-popup{
    background-color: transparent;
    width: 100%;
    display: flex;
    justify-content: center;
}
.cd-loading-box{
    padding: 30px;
    border-radius: 15px;
    background-color: rgba(33,33,33,.6);
    /* font-family: '黑体'; */
    font-weight: 600;
}
</style>