<template>
  <div class="container" :style="{paddingTop: headerShow?'60px' : '0'}">
    <div v-show="!pageLock">
      <cd-header
      v-if="this.$route.meta.header===undefined || !this.$route.meta.header"
      :title="$route.meta.title" 
      v-show="headerShow"
      @back="onBack" 
      :right-content="rightShow ? $route.meta.rightContent : ''"
      @rightClick="rightClick"  />
      <router-view @getRightClick="(val) => {headRightClick = val}" @getBackClick="updateBackClick" @rightShow="(flag) => {rightShow=flag}"/>
    </div>
    <!-- <cd-loading v-model="pageLock" text="加载中"></cd-loading> -->
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  data () {
    return {
      customBack : new Function(),
      rightShow : true
    }
  },
  computed: {
    ...mapGetters(['pageLock']), 
    headerShow(){
      return this.$route.meta.header || this.$route.meta.header===undefined
    }
  },
  methods: {
    back () {   // header箭头默认后退，路由设置跳转路径
      if (this.$route.meta.backUrl) {
        this.$router.push({
          path: this.$route.meta.backUrl,
          query: this.$route.query
        })
      } else {
        window.history.back()
      }
    },
    updateBackClick (back) {
      this.customBack = back && typeof back == 'function' ? back : this.back
    },
    onBack () {
      if (this.$route.meta.isNativeBack) {
        this.back()
      }else {
        this.customBack()
      }
    },
    rightClick () {
        this.headRightClick()
    },
  }
}
</script>

<style lang="less">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
