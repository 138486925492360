import Header from './header'
import Treeview from './tree'
import Loading from './loading'

const components = [
    Header,
    Treeview,
    Loading
]

const install = function (Vue) {
  components.forEach(component => {
    Vue.component(component.name, component)
  })
}

export default {
  install,
  Header,
  Treeview,
  Loading
}